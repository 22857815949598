import  {
    IntlMessageValue
} from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any,
    x?:any,
    setx?:any
) {


    const titel = (
        <>

        </>
    )

    const description = (
        <>
        </>
    )

    const CreateDefaultFields = (length:number) => {
        let DefaultHolder = []

        for(let c = 0;c<length;c++){

            let ClassBottom = '';
            let ClassTop= '';

            if(response.KdData[c].asp === 1) {
                ClassBottom = 'multiborderBottomActive';
                ClassTop= 'multiborderComplete';
            } else {
                ClassBottom = 'multiborderBottom';
                ClassTop= 'multiborderTop';
            }

            if(response.KdData[c].asp === 1) {
                DefaultHolder.push(
                    {
                        formType: 'input',
                        type: 'hidden',
                        name: 'KdData['+(x)+'][id]',
                        value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].id !== undefined ? response.KdData[c].id : '0') : '0'
                    },
                    {
                        formType: 'header',
                        type:"h3",
                        value:"Ansprechpartner",
                        className:"text-center"
                    },
                    {
                        formType: 'multiply',
                        classRow:ClassTop,
                        cols: [

                            {
                                formType: 'input',
                                label: IntlMessageValue('de', 'customer.table.name', Store),
                                required: true,
                                type: 'text',
                                placeholder: IntlMessageValue('de', 'common.member.name', Store),
                                name: 'KdData['+(x)+'][name]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].name !== undefined ? response.KdData[c].name : '') : ''
                            },
                            {
                                formType: 'input',
                                label: 'Telefon',
                                type: 'text',
                                placeholder:  'Bitte Telefonnummer eingeben',
                                name: 'KdData['+(x)+'][phone]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].phone !== undefined ?response.KdData[c].phone : '') : ''
                            },
                            {
                                formType: 'input',
                                label: 'Mobil (Ansprechpartner)',
                                type: 'text',
                                placeholder: 'Bitte Mobil (Ansprechpartner) eingeben',
                                name: 'KdData['+(x)+'][area]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].area !== undefined ?response.KdData[c].area : '') : ''
                            },
                            {
                                formType: 'input',
                                label: 'E-Mail',
                                required: true,
                                type: 'text',
                                placeholder: IntlMessageValue('de', 'common.member.email', Store),
                                name: 'KdData['+(x)+'][email]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].email !== undefined ?response.KdData[c].email : '') : ''
                            }

                        ]
                    },{
                        formType: 'input',
                        label: 'Ansprechpartner',
                        required: true,
                        type: 'hidden',
                        placeholder: IntlMessageValue('de', 'common.member.email', Store),
                        name: 'KdData['+(x)+'][asp]',
                        value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].asp !== undefined ?response.KdData[c].asp : 0):0
                    }
                )
            } else {
                DefaultHolder.push(
                    {
                        formType: 'input',
                        type: 'hidden',
                        name: 'KdData['+(x)+'][id]',
                        value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].id !== undefined ? response.KdData[c].id : '0') : '0'
                    },

                    {
                        formType: 'header',
                        type:"h3",
                        value:"Einsatzadresse",
                        className:"text-center"
                    },
                    {
                        formType: 'multiply',
                        classRow:ClassTop,
                        cols: [
                            {
                                formType: 'input',
                                label: IntlMessageValue('de', 'customer.table.name', Store),
                                required: true,
                                type: 'text',
                                placeholder: IntlMessageValue('de', 'common.member.name', Store),
                                name: 'KdData['+(x)+'][name]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].name !== undefined ? response.KdData[c].name : '') : ''
                            },{
                                formType: 'input',
                                label: IntlMessageValue('de', 'customer.table.street', Store),
                                required: true,
                                type: 'text',
                                placeholder: IntlMessageValue('de', 'common.member.street', Store),
                                name: 'KdData['+(x)+'][street]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].street !== undefined ?response.KdData[c].street : '') : ''
                            },
                            {
                                formType: 'input',
                                label: 'PLZ/'+IntlMessageValue('de', 'customer.table.city', Store),
                                required: true,
                                type: 'text',
                                placeholder: IntlMessageValue('de', 'common.member.city', Store),
                                name: 'KdData['+(x)+'][city]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].city !== undefined ? response.KdData[c].city : '') : ''
                            }
                        ]
                    },
                    {
                        formType: 'multiply',
                        classRow:ClassBottom,
                        cols: [
                            {
                                formType: 'input',
                                label: 'Telefon',
                                type: 'text',
                                placeholder:  'Bitte Telefonnummer eingeben',
                                name: 'KdData['+(x)+'][phone]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].phone !== undefined ?response.KdData[c].phone : '') : ''
                            },
                            {
                                formType: 'input',
                                label: 'Ansprechpartner (Standort) ',
                                type: 'text',
                                placeholder: 'Bitte Ansprechpartner (Standort) eingeben',
                                name: 'KdData['+(x)+'][area]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].area !== undefined ?response.KdData[c].area : '') : ''
                            },
                            {
                                formType: 'input',
                                label: 'E-Mail',
                                required: true,
                                type: 'text',
                                placeholder: IntlMessageValue('de', 'common.member.email', Store),
                                name: 'KdData['+(x)+'][email]',
                                value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].email !== undefined ?response.KdData[c].email : '') : ''
                            }

                        ]
                    },{
                        formType: 'input',
                        label: 'Ansprechpartner',
                        required: true,
                        type: 'hidden',
                        placeholder: IntlMessageValue('de', 'common.member.email', Store),
                        name: 'KdData['+(x)+'][asp]',
                        value: newElement === false ? (response.KdData[c] !== undefined  && response.KdData[c] !== null && response.KdData[c].asp !== undefined ?response.KdData[c].asp : 0):0
                    }
                )
            }


            x = x+ 1;
        }
        x = x+ 1;
        return DefaultHolder;

    }

    const dataColOne = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'id',
            value: newElement === false ? response.id : '0'
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.table.kdnr', Store),
                    required: true,
                    type: 'text',
                    readonly:true,
                    placeholder: IntlMessageValue('de', 'common.member.kdnr', Store),
                    name: 'DESTDEBNR',
                    value: newElement === false ? response.DESTDEBNR : '0'
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.table.name', Store),
                    required: true,
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.member.name', Store),
                    name: 'Name1',
                    value: newElement === false ? response.Name1 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.table.name2', Store),
                    required: true,
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.member.name', Store),
                    name: 'Name2',
                    value: newElement === false ? response.Name2 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.table.city', Store),
                    required: true,
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.member.city', Store),
                    name: 'Ort',
                    value: newElement === false ? response.Ort : ''
                },{
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.table.street', Store),
                    required: true,
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.member.street', Store),
                    name: 'Strasse',
                    value: newElement === false ? response.Strasse : ''
                },{
                    formType: 'input',
                    label: 'Telefon1',
                    required: true,
                    type: 'text',
                    placeholder: 'Bitte Telefon1 eingeben',
                    name: 'Telefon1',
                    value: newElement === false ? response.Telefon1 : ''
                },{
                    formType: 'input',
                    label: 'EMail',
                    required: true,
                    type: 'text',
                    placeholder: 'Bitte EMail eingeben',
                    name: 'EMail',
                    value: newElement === false ? response.EMail : ''
                },
            ]
        },
        {
            formType: 'MultiAdded',
            addedfunc:setx,
            addedvalue:x,
            color:' rgba(19, 94, 126, 0.8)',
            icon:'add_circle',
            firstID:'KdData[0]',
            inputId: 'addMember',
            inputName:'member',
            newMulti:true,
            OnlyDefault:true,
            defaultField:[

                (response.KdData !== undefined ? CreateDefaultFields(response.KdData.length) : [])
            ],
            injecktelement:[]
        },
      /*  {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.table.clerk1', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.member.clerk1', Store),
                    name: 'Sachbearbeiter1',
                    value: newElement === false ? response.Sachbearbeiter1 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.table.clerk2', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.member.clerk2', Store),
                    name: 'Sachbearbeiter2',
                    value: newElement === false ? response.Sachbearbeiter2 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.common.report_on', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.member.report_on', Store),
                    name: 'melden_bei',
                    value: newElement === false ? response.melden_bei : ''
                }
            ]
        },*/
       /* {
            formType: 'textarea',
            label: <IntlMessage Store={Store} messageId="common.stats.tab.remarks" />,
            type: 'textarea',
            max: 500,
            id: 'seo_title',
            seo: true,
            row:5,
            name: 'translation[seo][seo_title]',
            placeholder: IntlMessageValue(
                'de',
                'common.provide_pagetitle',
                Store
            ),
            //'legende': <IntlMessage Store={Store} messageId="seo.title.legend" />,
            value:''
        }*/
        /*{
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: 'Status_Gelb',
                    type: 'text',
                    placeholder: 'Bitte Status_Gelb eingeben',
                    name: 'Status_Gelb',
                    value: newElement === false ? response.Status_Gelb : ''
                },
                {
                    formType: 'input',
                    label: 'Status_gruen',
                    type: 'text',
                    placeholder: 'Bitte Status_gruen eingeben',
                    name: 'Status_gruen',
                    value: newElement === false ? response.Status_gruen : ''
                }
            ]
        },*/
        /*{
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.common.ag', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.ag.abbreviation', Store),
                    name: 'AG',
                    value: newElement === false ? response.AG : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'customer.common.kd', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.kd.abbreviation', Store),
                    name: 'KD',
                    value: newElement === false ? response.KD : ''
                }
            ]
        },*/
    ]

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'dataColOne') {
        return dataColOne
    } else if (conf === 'dataColtwo') {
        return;
    }
}

export default configuration
